import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext } from "../App";

function Login() {

    const { dispatch } = useContext(UserContext);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const loginUser = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch('https://backend-college.onrender.com/login', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            })

            const data = await res.json();
            console.log(data);

            if (res.status === 400 || !data) {
                window.alert("Invalid Credential");
                console.log("Invalid Credential");
            } else {
                dispatch({ type: 'USER', payload: true })
                window.alert("Login Successfully");
                console.log("Login Successfully");
                navigate('/');
            }
        } catch (error) {
            console.log("This is the Error : " + error);
        }
    }

    return (
        <div className="main-register">
            <div className="login-menu">

                <div className="text-input">
                    <h3>Login</h3>
                    <p>Click here to login</p>
                </div>

                <div className="password-input">
                    <input type="text" name="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                    <input type="text" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    <button className="login-button" type="submit" name="login" id="login" onClick={loginUser}>Log in</button>
                </div>
                <h5>or</h5>
                <NavLink to="/register"><button type="submit" name="register" id="register" className="register-button">Create new Account</button></NavLink>
            </div>

        </div>
    )
}

export default Login;