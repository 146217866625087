import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../images/back-five.jpg';

function Profile() {

    const navigate = useNavigate();

    const [userData, setUserData] = useState({});

    const callProfilePage = async () => {
        try {
            const res = await fetch('https://backend-college.onrender.com/profile', {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });

            const data = await res.json();
            console.log(data);
            setUserData(data);

            if (!res.status === 200) {
                const error = new Error(res.error);
                throw error;
            }


        } catch (error) {
            console.log(error);
            navigate('/');
        }
    }

    useEffect(() => {
        callProfilePage();
        //eslint-disable-next-line
    }, []);


    return (
        <div className="main-register">
            <div className="profile-menu">

                <div className="img-about">
                    <div className="dad-img">
                        <img className="background-profile" src={backgroundImage} alt="background-img" />
                        <img className="forground-profile" src={userData.image} alt="profile-img" />
                    </div>
                    {/* <img src={userData.profileimage} alt="profile-img" /> */}
                    <h3>{userData.firstname} {userData.lastname}</h3>
                    <p>{userData.work}</p>
                    <p>{userData.address}</p>
                </div>

                <div className="text-about">
                    <table>
                        <tr>
                            <td>Email</td>
                            <td className="email-text-profile">{userData.email}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{userData.phone}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{userData.gender}</td>
                        </tr>
                        <tr>
                            <td>Branch</td>
                            <td>{userData.branch}</td>
                        </tr>
                        <tr>
                            <td>Year</td>
                            <td>{userData.year}</td>
                        </tr>
                        <tr>
                            <td>Birth Date</td>
                            <td>{userData.birthDate}</td>
                        </tr>
                        <tr>
                            <td>Hobby</td>
                            <td>{userData.hobby}</td>
                        </tr>
                    </table>
                </div>


                <div className="social-about">
                    <table>
                        <tr>
                            <td>Website</td>
                            <td className="email-text-profile">{userData.website}</td>
                        </tr>
                        <tr>
                            <td>LinkedIn</td>
                            <td>{userData.linkedin}</td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            <td>{userData.facebook}</td>
                        </tr>
                        <tr>
                            <td>Instagram</td>
                            <td>{userData.instagram}</td>
                        </tr>
                        <tr>
                            <td>Snapchat</td>
                            <td>{userData.snapchat}</td>
                        </tr>
                        <tr>
                            <td>Twitter</td>
                            <td>{userData.twitter}</td>
                        </tr>
                    </table>
                </div>

                <div className="about-final-text">
                    <h3>About me</h3>
                    <p>{userData.about}</p>
                </div>


            </div>
        </div>
    )
}

export default Profile;

