import React, { useContext } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../App';

function Navbar() {

    const { state } = useContext(UserContext);

    const RenderMenu = () => {
        if (state) {
            return (
                <>
                    <li className="nav-item">
                        <NavLink className="nav-links active" aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/profile">Profile</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/main">Main</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/news">News</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/about">About</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/contact">Contact</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/logout"><button className="btn-nav">LOGOUT</button></NavLink>
                    </li>
                </>
            )
        } else {
            return (
                <>
                    <li className="nav-item">
                        <NavLink className="nav-links active" aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/news">News</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/about">About</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-links" to="/contact">Contact</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link button-nav" to="/register"><button className="btn-nav">REGISTER</button></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/login"><button className="btn-nav">LOGIN</button></NavLink>
                    </li>
                </>
            )
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container-fluid ms-2 me-2 ms-4 me-3">
                    <NavLink className="navbar-brand" to="/">
                        <h1 className="custom-brand">CollageBook</h1>
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <RenderMenu></RenderMenu>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;