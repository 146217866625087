/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import profileImg from '../images/profile-placeholder.png';
import backgroundImage from '../images/back-four.jpg';

function Main() {

    const navigate = useNavigate();
    const [profileData, setprofileData] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const callprofilePage = async () => {
        try {
            const res = await fetch('https://backend-college.onrender.com/main', {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });

            const data = await res.json();
            console.log(data);
            setprofileData(data);

            if (!res.status === 200) {
                const error = new Error(res.error);
                throw error;
            }

        } catch (error) {
            console.log(error);
            navigate('/profile');
        }
    }

    useEffect(() => {
        callprofilePage();
        //eslint-disable-next-line
    }, [])



    return (
        <div>
            <div className="main-register" >
                <div className="val-menu">

                    {/* This is for the Search Term  */}
                    <div className="search-profile">
                        <h4>Search Profile</h4>
                        <input className="main-search-input" type="text" placeholder="Search..." onChange={event => { setSearchTerm(event.target.value) }} />
                    </div>


                    {/* This will filter the result before showing to the user */}
                    {profileData.filter(val => {
                        if (searchTerm === "") {
                            return val;
                        } else if (val.firstname.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return val;
                        }

                    }).map(function (val, key) {

                        return (
                            <div className="profile-div-background" key={key}>

                                <div className="profile-menu">

                                    <div className="img-about">
                                        <div className="dad-img">
                                            <img className="background-profile" src={backgroundImage} alt="background-img" />
                                            <img className="forground-profile" src={val.image} alt="profile-img" />
                                        </div>
                                        <h3>{val.firstname} {val.lastname}</h3>
                                        <p>{val.work}</p>
                                        <p>{val.address}</p>
                                    </div>

                                    <div className="text-about">
                                        <table>
                                            <tr>
                                                <td>Email</td>
                                                <td>{val.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td>{val.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Gender</td>
                                                <td>{val.gender}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch</td>
                                                <td>{val.branch}</td>
                                            </tr>
                                            <tr>
                                                <td>Year</td>
                                                <td>{val.year}</td>
                                            </tr>
                                            <tr>
                                                <td>Birth Date</td>
                                                <td>{val.birthDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Hobby</td>
                                                <td>{val.hobby}</td>
                                            </tr>
                                        </table>
                                    </div>


                                    <div className="social-about">
                                        <table>
                                            <tr>
                                                <td>Website</td>
                                                <td>{val.website}</td>
                                            </tr>
                                            <tr>
                                                <td>Facebook</td>
                                                <td>{val.facebook}</td>
                                            </tr>
                                            <tr>
                                                <td>Instagram</td>
                                                <td>{val.instagram}</td>
                                            </tr>
                                            <tr>
                                                <td>Snapchat</td>
                                                <td>{val.snapchat}</td>
                                            </tr>
                                            <tr>
                                                <td>Twitter</td>
                                                <td>{val.twitter}</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="about-final-text">
                                        <h3>About me</h3>
                                        <p>{val.about}</p>
                                    </div>


                                </div>

                            </div>
                        )
                    })};

                </div>
            </div>
        </div>
    )
}

export default Main;