import React from "react";
// import image1 from '../images/img1.jpg';
// import image2 from '../images/img2.jpg';
// import image3 from '../images/img3.jpg';
// import image4 from '../images/img4.jpg';
// import videoAds from '../images/videoCollage.mp4';
import homeImg from '../images/home-page.png'
import { NavLink } from 'react-router-dom';

function Home() {

    return (
        <>
            <div className="main-home">

                <div className="home-menu">


                    <div className="section-one-home">
                        <div className="home-one-right">
                            <h1>Before connecting to world let me connect with your nearones.</h1>
                            <h4>Connect with your collage Friends and learn the communication in Person.</h4>
                            <p>I know being online or active on the social-media all the time will not going to help in your personal developement. So get out and start using this website and get social in real world. Start now by clicking the button below.</p>
                            <NavLink to="/register"><button>Start Now</button></NavLink>
                        </div>

                        <div className="home-one-left">
                            <img src={homeImg} alt="" />
                        </div>
                    </div>

                    <div className="social-link">
                        <h1>Developers From Crio Have Cracked Careers In</h1>
                        <div className="social-link-div">
                            <i className="fab fa-facebook fab-icon"></i>
                            <i className="fab fa-linkedin fab-icon"></i>
                            <i className="fab fa-twitter-square fab-icon"></i>
                            <i className="fas fa-users fab-icon"></i>
                            <i className="fab fa-instagram-square fab-icon"></i>
                            <i className="fab fa-snapchat fab-icon"></i>
                            <i className="fab fa-github-square fab-icon"></i>
                        </div>
                    </div>


                    {/* <div className="section-image-div-home">
                        <h3>This is the Testomonial Page</h3>
                        <div>
                            <img src={image1} />
                            <img src={image2} />
                            <img src={image3} />
                            <img src={image4} />
                        </div>
                    </div> */}

                    <div className="section-two-home">
                        <h1>Modern In Person Communication Approach is here!!</h1>
                        <p>At CollageBook You will exactly going to learn the techniques to knowing someone and making Friends. First we will start with knowing each other little then start offline.</p>
                        <p>Be Genuen with the person, to whom you are talking or want to make friends. Leave the rest of things for us.</p>

                        <div className="line-hr"></div>
                        <div className="card-div-home">

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/23/02/35/youtube-2433301_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/22/14/23/twitter-2430933_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/22/06/22/facebook-2429746_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                        </div>

                        <div className="card-div-home">

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/23/02/35/youtube-2433301_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/22/14/23/twitter-2430933_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                            <div className="card-div-center">
                                <img src="https://cdn.pixabay.com/photo/2017/06/22/06/22/facebook-2429746_960_720.png" alt="img" />
                                <h1>Experience building a Google News Daily</h1>
                                <p>Build a tool and scalable backend like google news.</p>
                            </div>

                        </div>
                    </div>



                    <div className="section-four-home">
                        <h2>Never Stop</h2>
                        <h1>Developing</h1>
                        <p>Ready to try CollageBook ? Click the button below to get started ?</p>
                        <NavLink to="/register"><button>Start fro Free</button></NavLink>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Home;