import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <>
            <footer className="py-2 footer">
                <div className="line-div">
                </div>
                
                <p className="nav-link">Find an issue with this page?<a href="https://github.com/mrprince123" className="footer-link"> Fix it on Github</a></p>
                <p className="nav-link">Need help? Email <span className="nav-linkt">princeparker124@gmail.com</span></p>
                <ul className="nav justify-content-center footer-social-link">
                    <li className=""><a href="https://github.com/mrprince123" className="nav-linki px-2 footer-link"><i
                        className="fab fa-github"></i></a></li>
                    <li className=""><a href="https://www.linkedin.com/in/prince-kumar-sahni-7583b41a5/"
                        className="nav-linki px-2 footer-link"><i className="fab fa-linkedin"></i></a></li>
                    <li className=""><a href="https://twitter.com/MrPrince185" className="nav-linki px-2 footer-link"><i
                        className="fab fa-twitter"></i></a></li>
                </ul>
                <h3 className="nav-linkt help-text">HELPFUL LINKS</h3>
                <ul className="nav justify-content-center footer-nav-link">
                    <li className="footer-link"><NavLink to="/" className="nav-linkf px-2">Home</NavLink></li>
                    <li className="footer-link"><NavLink to="/main" className="nav-linkf px-2">Main</NavLink></li>
                    <li className="footer-link"><NavLink to="/about" className="nav-linkf px-2">About</NavLink></li>
                    <li className="footer-link"><NavLink to="/contact" className="nav-linkf px-2">Contact</NavLink></li>
                </ul>
                <p className="text-center">Copyright © 2022 Prince Kumar Sahni LLC</p>
            </footer>
        </>
    )
}

export default Footer;