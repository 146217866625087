import React, { useEffect, useState } from "react";
import Axios from 'axios';

function News() {

    const [newsData, setNewsData] = useState([]);

    const callNewArticle = async () => {
        try {
            // let pageSize = 10;
            const url1 = "https://newsapi.org/v2/top-headlines?country=in&category=technology&apiKey=9d12903806bd47c2b5e70d25cd09c9ca";
            // const url2 = "https://newsapi.org/v2/top-headlines?country=in&category=science&apiKey=9d12903806bd47c2b5e70d25cd09c9ca";
            // const url = "https://newsapi.org/v2/top-headlines?sources=techcrunch&pageSize=15&apiKey=9d12903806bd47c2b5e70d25cd09c9ca";
            // const urlInsta = "https://newsapi.org/v2/everything?q=technology&apiKey=9d12903806bd47c2b5e70d25cd09c9ca";
            Axios.get(url1)
                .then((response) => {
                    console.log(response);

                    setNewsData(response.data.articles)

                }).catch((err) => {
                    console.log(err);
                })

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        callNewArticle();
    }, [])

    return (
        <>
            <div className="main-register">
                <div className="">
                    <div className="news-text-main">
                        <h1>News Section</h1>
                        <hr className="hr-text"></hr>
                        <p>Get the latest news for you, This news is keeps updating day by day for every 24 hours. So don't get boared we will update another news for you. We are focusing on the personalized data for you. So that we can delive the best of the news content to you. Stay tuned for the next one.</p>
                    </div>
                    {newsData.map(function (value, key) {
                        return (
                            <div className="news-menu" key={key}>

                                <div className="img-news-div">
                                    <img src={value.urlToImage} alt="img url" />
                                    <h4>author : {value.author}</h4>
                                </div>

                                <div className="text-news-div">
                                    <h1>{value.title}</h1>
                                    <p>description :{value.description} </p>
                                    <button><a href={value.url}>More details</a></button>
                                </div>
                            </div>
                        );
                    })};
                </div>
            </div>

        </>
    )
}

export default News;