import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Contact() {


    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [purpose, setPurpose] = useState('');
    const [message, setMessage] = useState('');

    const loginUser = async (e) => {
        e.preventDefault();

        const res = await fetch('https://backend-college.onrender.com/contact', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                purpose: purpose,
                message: message
            })
        })

        const data = await res.json();

        if (res.status === 400 || !data) {
            window.alert("Please fill all the fields");
            console.log("Pleaes fill all the fields");
        } else {
            window.alert("Message Send Successfully");
            console.log("Message Send Successfully");
            navigate('/thanks');
        }
    }


    return (

        <div className="main-contact">

            <div className="contact-data">
                <div className="contact-email">
                    <h1>Email</h1>
                    <p>princeparker124@gamil.com</p>
                </div>
                <div className="contact-email">
                    <h1>Phone</h1>
                    <p>+91 9834983493</p>
                </div>
            </div>

            <div className="contact-menu">

                <div className="text-input">
                    <h3>Contact us</h3>
                    <p>Drop us a Line</p>
                </div>

                <div className="password-input">
                    <input type="text" name="name" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} ></input>
                    <input type="email" name="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                    <input type="text" name="purpose" placeholder="Purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)}></input>
                    <textarea rows="5" type="text" name="message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                    <button className="contact-button" type="submit" name="login" id="login" onClick={loginUser}>Send Message</button>
                </div>
            </div>

        </div>
    )
}

export default Contact;