import React, { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';

function Register() {


    const navigate = useNavigate();
    // const [profileimage, setProfileImage] = useState('');
    const [firstname, setfirstName] = useState('');
    const [lastname, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [gender, setGender] = useState('');
    const [branch, setBranch] = useState();
    const [year, setYear] = useState();
    const [work, setWork] = useState('');
    const [website, setWebsite] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [snapchat, setSnapchat] = useState('');
    const [twitter, setTwitter] = useState('');
    const [hobby, setHobby] = useState('');
    const [about, setAbout] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');

    // console.log(profileimage, 12);

    const PostData = async (e) => {
        e.preventDefault();


        const res = await fetch('https://backend-college.onrender.com/register', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstname: firstname,
                lastname: lastname,
                email: email,
                phone: phone,
                address: address,
                birthDate: birthDate,
                gender: gender,
                branch: branch,
                year: year,
                work: work,
                website: website,
                linkedin: linkedin,
                facebook: facebook,
                instagram: instagram,
                snapchat: snapchat,
                twitter: twitter,
                hobby: hobby,
                about: about,
                password: password,
                cpassword: cpassword
            })
        });


        const data = await res.json();

        if (res.status === 422 || !data) {
            window.alert("Invalid Registration");
            console.log("Invalid Registration");
        } else {
            window.alert("Registration Successfull");
            console.log("Registration Successfull");
            navigate('/login');
        }
    };


    return (
        <div className="main-register">
            <div className="register-menu">

                <div className="text-input">
                    <h3>Sign Up</h3>
                    <p>It's quick and easy</p>
                </div>


                <div className="name-input"></div>

                {/* <div className="image-input">
                    <input type="file" onChange={(e) => setProfileImage(e.target.files[0])} />
                </div> */}

                <div className="name-input">
                    <input type="text" value={firstname} onChange={(e) => setfirstName(e.target.value)} name="firstname" placeholder="First name"></input>
                    <input type="text" value={lastname} onChange={(e) => setlastName(e.target.value)} name="lastname" placeholder="Surname"></input>
                </div>
                <div className="email-input">
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="email address"></input>
                    <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="Mobile number"></input>
                    <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="address" placeholder="Address"></input>
                </div>
                <div className="birth-input">

                    <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} name="birthDate" placeholder="Date of birth"></input>

                    <div className="input-group">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Gender</label>
                        <select className="form-select" value={gender} onChange={e => setGender(e.target.value)} id="inputGroupSelect01">
                            <option selected>Select...</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>

                </div>


                <div className="branch-input">

                    <div className="input-group">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Branch</label>
                        <select className="form-select" value={branch} onChange={e => setBranch(e.target.value)} id="inputGroupSelect01">
                            <option selected>Select...</option>
                            <option>CSE</option>
                            <option>CSE + AIML</option>
                            <option>CSE + Data Science</option>
                            <option>ENCE</option>
                            <option>ELCE</option>
                            <option>Mechenical</option>
                            <option>Civil</option>
                            <option>ECE</option>
                        </select>
                    </div>



                    <div className="input-group">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Year</label>
                        <select className="form-select" value={year} onChange={e => setYear(e.target.value)} id="inputGroupSelect01">
                            <option selected>Select...</option>
                            <option>1st</option>
                            <option>2nd</option>
                            <option>3rd</option>
                            <option>4th</option>
                        </select>
                    </div>


                </div>


                <div className="social-input">
                    <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} name="website" placeholder="Your website link"></input>
                    <input type="text" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} name="linkedin" placeholder="Linkedin Username or profile link"></input>
                    <input type="text" value={facebook} onChange={(e) => setFacebook(e.target.value)} name="facebook" placeholder="Facebook Username or profile link"></input>
                    <input type="text" value={instagram} onChange={(e) => setInstagram(e.target.value)} name="instagram" placeholder="Insta Username or profile link"></input>
                    <input type="text" value={snapchat} onChange={(e) => setSnapchat(e.target.value)} name="snapchat" placeholder="Snapchat Username or profile link"></input>
                    <input type="text" value={twitter} onChange={(e) => setTwitter(e.target.value)} name="twitter" placeholder="Twitter Username or profile link"></input>
                </div>
                <div className="hobby-input">
                    <input type="text" value={hobby} onChange={(e) => setHobby(e.target.value)} name="hobby" placeholder="Hobby"></input>
                    <input type="text" value={work} onChange={(e) => setWork(e.target.value)} name="work" placeholder="Work"></input>
                    <textarea rows="3" type="text" value={about} onChange={(e) => setAbout(e.target.value)} name="about" placeholder="About Yourself"></textarea>
                </div>
                <div className="password-input">
                    <input type="text" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"></input>
                    <input type="text" name="cpassword" value={cpassword} onChange={(e) => setCpassword(e.target.value)} placeholder="Conform Password"></input>
                </div>

                <div className="privacy-text">
                    <p>By clicking Sign Up. you agree to our <span><NavLink to="#privacy"> Terms, Privacy Policy </NavLink></span> and <span><NavLink to="#cookies" >Cookies Policy</NavLink></span></p>
                    <p>You may receive SMS notification from us and can opt out at any time.</p>
                </div>

                <button className="submit-button" value="Register" type="submit" name="register" onClick={PostData} id="register">Sign up</button>
            </div>

        </div>
    )
}

export default Register;