import React from "react";
import { NavLink } from 'react-router-dom';

function Thanks() {
    return (
        <div className="main-register">
            <div className="thanks-page">  
                <h2>Thanks</h2>
                <h4>Your Message Send Successfully.</h4>
                <h4>You will get reply soon, we will get back to you soon.</h4>
                <NavLink to="/"><button>Home Page</button></NavLink>
            </div>
        </div>
    )
}

export default Thanks;