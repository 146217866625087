import React from "react";
// import { NavLink } from 'react-router-dom';
// import manOne from "../images/man-one.jpg";
// import mantwo from "../images/man-two.jpg";
// import manthree from "../images/man-three.jpg";
// import manfour from '../images/man-four.jpg';
import prince from '../images/prince-pic.jpg';
// import manfive from '../images/man-five.jpg';

function About() {
    return (
        <div className="main-register">
            <div className="about-page">

                {/* <h1 className="h1-text">About us</h1> */}

                <div className="div-h1">
                    <img src="https://cdn.pixabay.com/photo/2016/03/31/20/41/countries-1295969_960_720.png" alt="img" />
                    <h1 className="h1-color">About Us</h1>
                    <h1>CollegeBook is here to connect everyone, regardless of not having their phone number or insta ID. connect and unlock your full potential together.</h1>
                </div>


                <hr className="hr-text"></hr>

                <p>Our Main goal is to connect poeple across this college. Don't worry if you don't know their name or number. We are here to help you with the help of this platform. Here You just need to put your details like your name, branch, year or you can also add you instaID or facebookID or snapchatID to get connect beyound this platform. I hope best for you and your friendship journey.</p>


                <div className="div-h2">
                    <h1>Reduce the Platform Gap</h1>
                    <hr className="hr-text"></hr>
                    <p>We want all-in-one where people can come and get all the data about their friends like their name, their LinkedIn ID , about them and what they like and many more at one place at once. Don't worry about your data all these data are safe with us. </p>
                </div>

                <div className="div-h3">
                    <h1>who am I ?</h1>


                    <div className="div-img">
                        <div className="div-img-div">
                            <img className="three" src={prince} alt="man-three" />
                            <h3>Developer 3</h3>
                            <p>Data Scientist</p>
                            <ul className="nav justify-content-center about-social-link">
                                <li className=""><a href="https://github.com/mrprince123" className="nav-linkk px-2 footer-link"><i
                                    className="fab fa-github"></i></a></li>
                                <li className=""><a href="https://www.linkedin.com/in/prince-kumar-sahni-7583b41a5/"
                                    className="nav-linkk px-2 footer-link"><i className="fab fa-linkedin"></i></a></li>
                                <li className=""><a href="https://twitter.com/MrPrince185" className="nav-linkk px-2 footer-link"><i
                                    className="fab fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <hr className="hr-text"></hr> */}
                    <p>CollegeBook is founded and developed by Prince Kumar Sahni he is third year btech student with vast experience in buildinig engineering techstack and up-skilling them like Android applications and Web Applications and many more.</p>
                </div>



            </div>
        </div>
    )
}

export default About;