import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Main from './components/Main';
import Profile from './components/Profile';
import Contact from './components/Contact';
import Login from './components/Login';
import Register from './components/Register';
import Logout from './components/Logout';
import Error from './components/Error';
import Footer from './components/Footer';
import About from './components/About';
import News from './components/News';
import Thanks from './components/Thanks';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initialState, reducer } from './reducer/UseReducer';
import React, { createContext, useReducer } from 'react';

export const UserContext = createContext();

function App() {

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div>

      <UserContext.Provider value={{ state, dispatch }}>

        <Router>
          <Navbar />
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/main' exact element={<Main />} />
            <Route path="/news" exact element={<News />} />
            <Route path='/profile' exact element={<Profile />} />
            <Route path='/contact' exact element={<Contact />} />
            <Route path='/login' exact element={<Login />} />
            <Route path='/logout' exact element={<Logout />} />
            <Route path='/register' exact element={<Register />} />
            <Route path='/about' exact element={<About />} />
            <Route path='/thanks' exact element={<Thanks />} />
            <Route path='/*' exact element={<Error />} />
          </Routes>
          <Footer />
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
